import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "input", "searchIcon", "searchButton", "clearIcon"]
  static classes = [
    "clearIconVisibility",
    "inputPadding",
    "searchIconVisibility",
  ]

  connect() {
    this.clearIconTarget.classList.add(...this.clearIconVisibilityClasses)
    this.searchButtonTarget.classList.add(this.searchIconVisibilityClass)
  }

  submit() {
    this.inputTarget.blur()
    this.clear()
  }

  submitForm() {
    this.formTarget.requestSubmit()
  }

  valueChange() {
    const inputLength = this.inputTarget.value.length
    this.inputTarget.classList.toggle(this.inputPaddingClass, inputLength > 0)
    this.searchIconTarget.classList.toggle(
      this.searchIconVisibilityClass,
      inputLength > 0
    )
    this.clearIconVisibilityClasses.forEach((className) => {
      this.clearIconTarget.classList.toggle(className, inputLength === 0)
    })
    this.searchButtonTarget.classList.toggle(
      this.searchIconVisibilityClass,
      inputLength == 0
    )
  }

  clear() {
    this.inputTarget.value = ""
    this.clearIconTarget.classList.add(...this.clearIconVisibilityClasses)
    this.searchIconTarget.classList.remove(...this.searchIconVisibilityClasses)
    this.inputTarget.classList.remove(this.inputPaddingClass)
    this.searchButtonTarget.classList.add(this.searchIconVisibilityClass)
  }
}
